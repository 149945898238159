import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    ConfirmPaymentDailog: { isOpen: false, isError: false },
    paymentHistory: null,
    DroneList: [],
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        LoginRequest: (state) => {
            state.loading = true;
            // console.log(state.loading, action)            
        },
        LoginSuccess: (state, action) => {
            state.loading = false;
            state.user = action.payload;
            localStorage.setItem('token', action.payload.token)
            state.isAuthenticated = true;
        },
        LoginFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },
        autoLoginRequest: (state) => {
            state.loading = true;
            // console.log(state.loading, action)            
        },
        autoLoginSuccess: (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        autoLoginFailure: (state, action) => {
            state.loading = false;
            state.isAuthenticated = false;
            localStorage.removeItem('token')
        },

        LogoutRequest: (state) => {
            state.loading = true;
        },
        LogoutSuccess: (state) => {
            state.loading = false;
            state.user = null;
            state.isAuthenticated = false;
        },
        LogoutFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },



        RegisterRequest: (state) => {
            state.loading = true;

        },
        RegisterSuccess: (state, action) => {
            state.loading = false;
            state.userData = action.payload;

            state.isAuthenticated = false;
        },
        RegisterFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },


        VerifyOtpRequest: (state) => {
            state.loading = true;

        },
        VerifyOtpSuccess: (state, action) => {
            state.loading = false;
            // state.user = action.payload;

            state.isAuthenticated = false;
        },
        VerifyOtpFailure: (state, action) => {
            state.loading = false;
            // state.error = action.payload;
            state.isAuthenticated = false;
        },


        resendOtpRequest: (state) => {
            state.loading = true;

        },
        resendOtpSuccess: (state, action) => {
            state.loading = false;
            // state.user = action.payload;

            state.isAuthenticated = false;
        },
        resendOtpFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.isAuthenticated = false;
        },


        forgetPasswordLinkRequest: (state) => {
            state.loading = true;

        },
        forgetPasswordLinkSuccess: (state, action) => {
            state.loading = false;
            // state.user = action.payload;

            state.isAuthenticated = false;
        },
        forgetPasswordLinkFailure: (state, action) => {
            state.loading = false;
            // state.error = action.payload;
            state.isAuthenticated = false;
        },


        changePasswordRequest: (state) => {
            state.loading = true;

        },
        changePasswordSuccess: (state, action) => {
            state.loading = false;
            // state.user = action.payload;
            state.isAuthenticated = false;
        },
        changePasswordFailure: (state, action) => {
            state.loading = false;
            // state.error = action.payload;
            state.isAuthenticated = false;
        },

        Logout: (state) => {
            state.isAuthenticated = false
            localStorage.removeItem('token');
            state.user = null;
        },

        LoadUserRequest: (state) => {

            state.loading = true;
        },
        LoadUserSuccess: (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        LoadUserFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload
            state.isAuthenticated = false;
        },

        GetSubscriptionPlansRequest: (state, action) => {
            state.loading = true;
            state.plans = null;
        },
        GetSubscriptionPlansSuccess: (state, action) => {
            state.loading = false;
            state.plans = action.payload;
        },
        GetSubscriptionPlansFailure: (state, action) => {
            state.loading = false;
            state.plans = null;
            // state.isAuthenticated = false;
        },

        SelectPlanAction: (state, action) => {
            state.selectedPlan = action.payload;
        },

        CustomerPurchasePlanRequest: (state, action) => {
            state.loading = true;
            state.ConfirmPaymentDailog.isError = false;
            state.ConfirmPaymentDailog.isOpen = false;
            // state.plans = null;
        },
        CustomerPurchasePlanSuccess: (state, action) => {
            state.loading = false;
            state.ConfirmPaymentDailog.isError = false;
            state.ConfirmPaymentDailog.isOpen = true;
            // state.plans = action.payload;
        },
        CustomerPurchasePlanFailure: (state, action) => {
            state.loading = false;
            state.ConfirmPaymentDailog.isError = true;
            state.ConfirmPaymentDailog.isOpen = true;
        },

        ClosePaymentDialog: (state, action) => {
            state.ConfirmPaymentDailog.isError = false;
            state.ConfirmPaymentDailog.isOpen = false;
        },

        OpenPaymentDialog: (state, action) => {
            state.ConfirmPaymentDailog.isError = action.payload;
            state.ConfirmPaymentDailog.isOpen = true;
        },

        UpdateProfileRequest: (state, action) => {
            state.loading = true;
        },
        UpdateProfileSuccess: (state, action) => {
            state.loading = false;
            state.user = action.payload
        },
        UpdateProfileFailure: (state, action) => {
            state.loading = false;
        },

        PaymentHistoryRequest: (state, action) => {
            state.loading = true;
        },
        PaymentHistorySuccess: (state, action) => {
            state.loading = false;
            state.paymentHistory = action.payload
        },
        PaymentHistoryFailure: (state, action) => {
            state.loading = false;
        },

        changeAuthUserPasswordRequest: (state, action) => {
            state.loading = true;
        },
        changeAuthUserPasswordSuccess: (state, action) => {
            state.loading = false;
        },
        changeAuthUserPasswordFailure: (state, action) => {
            state.loading = false;
        },

        GetDroneListRequest: (state, action) => {
            state.loading = true;
        },
        GetDroneListSuccess: (state, action) => {
            state.loading = false;
            state.DroneList = action.payload;
        },
        GetDroneListFailure: (state, action) => {
            state.loading = false;
        },

        saveDroneRequest: (state, action) => {
            state.loading = true;
        },
        saveDroneSuccess: (state, action) => {
            state.loading = false;
        },
        saveDroneFailure: (state, action) => {
            state.loading = false;
        },
        deleteUserProfileRequest: (state, action) => {
            state.loading = true;
        },
        deleteUserProfileSuccess: (state, action) => {
            state.loading = false;
        },
        deleteUserProfileFailure: (state, action) => {
            state.loading = false;
        },


    }
});


export const {
    RegisterRequest, RegisterSuccess, RegisterFailure, LoginRequest, LoginSuccess, LoginFailure, VerifyOtpFailure, VerifyOtpSuccess,
    VerifyOtpRequest, resendOtpFailure, resendOtpSuccess, resendOtpRequest, forgetPasswordLinkFailure, forgetPasswordLinkSuccess, forgetPasswordLinkRequest, changePasswordFailure,
    changePasswordSuccess, changePasswordRequest, Logout, GetSubscriptionPlansRequest, GetSubscriptionPlansSuccess, GetSubscriptionPlansFailure, SelectPlanAction, CustomerPurchasePlanRequest,
    CustomerPurchasePlanSuccess, CustomerPurchasePlanFailure, UpdateProfileRequest, UpdateProfileSuccess, UpdateProfileFailure, ClosePaymentDialog, PaymentHistoryRequest, PaymentHistorySuccess, PaymentHistoryFailure,
    changeAuthUserPasswordFailure, changeAuthUserPasswordSuccess, changeAuthUserPasswordRequest, OpenPaymentDialog, autoLoginFailure, autoLoginSuccess, autoLoginRequest,
    GetDroneListRequest, GetDroneListSuccess, GetDroneListFailure, saveDroneRequest, saveDroneSuccess, saveDroneFailure, deleteUserProfileRequest, deleteUserProfileSuccess, deleteUserProfileFailure
} = userSlice.actions;

export default userSlice.reducer;