import CryptoJS from 'crypto-js';
/**
 * Returns the substring after the last occurrence of a dot in the given string.
 *
 * @param {string} str - The input string.
 * @returns {string} The substring after the last dot, or an empty string if no dot is found.
 */
export const getSubstringAfterLastDot = (str) => {
    const lastIndex = str.lastIndexOf('.');

    if (lastIndex === -1 || lastIndex === str.length - 1) {
        return '';
    }

    return str.substring(lastIndex + 1);
}

/**
 * Calculates the appropriate chunk size for a given file size.
 *
 * @param {number} fileSize - The size of the file in bytes.
 * @returns {number} The chunk size in bytes.
 */
export const calculateChunkSize = (fileSize) => {
    if (fileSize < 100 * 1024 * 1024) { // Less than 100MB
        return 5 * 1024 * 1024; // 5MB
    } else if (fileSize < 500 * 1024 * 1024) { // Between 100MB and 500MB
        return 20 * 1024 * 1024; // 20MB
    } else { // Greater than 500MB
        return 50 * 1024 * 1024; // 50MB
    }
}

/**
 * Encodes a string to UTF-8.
 *
 * @param {string} inputString - The string to encode.
 * @returns {Uint8Array} The UTF-8 encoded bytes.
 */
export const encodeToUtf8 = (inputString) => {
    const encoder = new TextEncoder();
    const utf8Bytes = encoder.encode(inputString);
    return utf8Bytes;
}

/**
 * Calculates the SHA-1 checksum of an array buffer.
 *
 * @param {ArrayBuffer} arrayBuffer - The array buffer to hash.
 * @returns {string} The SHA-1 hash in hexadecimal format.
 */
export const calculateSHA1 = (arrayBuffer) => {
    const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer);
    const sha1Hash = CryptoJS.SHA1(wordArray);
    return sha1Hash.toString(CryptoJS.enc.Hex);
}

/**
 * Formats a SQL date string to DD/MM/YYYY format.
 *
 * @param {string} inputDate - The input date string.
 * @returns {string} The formatted date string.
 */
export const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();

    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
}

/**
 * Array of abbreviated month names.
 * @type {string[]}
 */
export const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

/**
 * Formats a date to a string with abbreviated month and date.
 *
 * @param {string} inputDate - The input date string.
 * @returns {string} The formatted date string.
 */
export const formateDateToAbbreviatedMonthDate = (inputDate) => {
    const date = new Date(inputDate);
    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
}

/**
 * Calculates the next date based on the provided plan type.
 *
 * @param {string} providedDate - The provided date string.
 * @param {string} planType - The type of plan ("MONTH" or "YEAR").
 * @returns {string} The formatted date one month or year after the provided date.
 */
export const calculateNextDate = (providedDate, planType) => {
    const date = new Date(providedDate);
    let newDate;
    if (planType === "MONTH") {
        newDate = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    } else if (planType === "YEAR") {
        newDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
    }
    const formattedNewDate = newDate.toISOString();

    return formateDateToAbbreviatedMonthDate(formattedNewDate);
}

/**
 * Converts a size string to bytes.
 *
 * @param {string} sizeString - The size string (e.g., "5 MB").
 * @returns {number} The size in bytes.
 * @throws {Error} If the unit is invalid.
 */
export const convertToBytes = (sizeString) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    const [size, unit] = sizeString.split(' ');

    const unitIndex = units.indexOf(unit);
    if (unitIndex === -1) {
        throw new Error('Invalid unit');
    }

    let bytes = parseFloat(size);
    for (let i = 0; i < unitIndex; i++) {
        bytes *= 1024;
    }

    return bytes;
}

/**
 * Generates a unique ID.
 *
 * @returns {string} A unique ID.
 */
export const generateId = () => {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
}
