import React from 'react'
import './DashbordHeading.css'

const DashboardHeading = ({ heading, content }) => {
    return (
        <div className='dashboard-heading'>
            {heading && <h1>{heading}</h1>}
            {content && <p>{content}</p>}
        </div>
    )
}

export default DashboardHeading