import React from 'react'
import companyLogo from '../../assets/images/companyLogo.png'
import './CompanyLogo.css'

const CompanyLogo = () => {
    return (
        <a className='companyLogo'>
            A <span> <img src={companyLogo} alt="" srcset="" /> </span> SkyDatalogix Company
        </a>
    )
}

export default CompanyLogo