import React from 'react'
import './CommingSoon.css'
import spinner from '../../assets/images/spinner.png'

const CommingSoon = () => {
    return (
        <button className='comming-soon'>
            COMMING SOON
            <img src={spinner} alt="" />
        </button>
    )
}

export default CommingSoon