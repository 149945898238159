import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './SubscriptionPlan.css'
import SubscriptionCard from '../../components/SubscriptionCard/SubscriptionCard'
import Header from '../../components/Header/Header'
import { UseDispatch, useDispatch, useSelector } from 'react-redux'
import { getSubscripitonPlans } from '../../store/actions/user.actions'
import Loader from '../../components/Loader/Loader';
import useAutoLogin from '../../hooks/useAutoLogin';

const SubscriptionPlan = () => {
    useAutoLogin()
    const [planType, setPlanType] = useState('MONTH')
    const dispatch = useDispatch();
    const plans = useSelector((state) => state.user.plans)
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const user = useSelector((state) => state.user.user)
    const loading = useSelector((state) => state.user.loading)
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is authenticated
        if (!isAuthenticated) {
            // Redirect to login page
            // navigate('/login');
        } else {
            // Fetch subscription plans
            if (user.isSubscribed) {
                navigate('/dashboard')
            } else {
                dispatch(getSubscripitonPlans(planType));
            }
        }
    }, [dispatch, isAuthenticated, navigate, planType]);

    const onOptionChange = e => {
        setPlanType(e.target.value)
    }

    return (
        <>
            <Header isHeaderShow={false} />
            <div className='subscription_plan'>
                { loading && <Loader /> }
                <div className="subscription_heading">
                    <h1>Subscription Plans </h1>
                    <p>Start up and journey of creative transforming expedition with our unique subscription options.
                        For creative individuals, professional creatives or somewhere in between.
                        Select the plan that work well with your ideas and aspirations.</p>
                </div>


                <div className='mt-3 mb-4'>
                    <input
                        className='radio-btn'
                        type="radio"
                        id="contactChoice1"
                        name="contact"
                        value="MONTH"
                        checked={planType === "MONTH"}
                        onChange={onOptionChange}
                    />
                    <label className='radio-btn-label' for="contactChoice1"   >Billed Monthly</label>

                    <input
                        className='radio-btn'
                        type="radio"
                        id="contactChoice2"
                        name="contact"
                        value="YEAR"
                        checked={planType === "YEAR"}
                        onChange={onOptionChange}
                    />
                    <label className='radio-btn-label' for="contactChoice2">Billed Yearly</label>
                    {/* </div> */}
                </div>
                <div className='subscription_container d-flex justify-content-md-between flex-wrap mt-3 justify-content-sm-center align-items-sm-center'>
                    {plans &&
                        plans.map((plan) => {
                            return (
                                <SubscriptionCard
                                    key={plan.id}
                                    plan={plan}
                                />
                            )
                        })
                    }

                </div>

            </div>
        </>

    )
}

export default SubscriptionPlan