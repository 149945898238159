import React, { useEffect, useState } from 'react'
import DashboardHeading from '../../../components/DashboardHeading/DashboardHeading'
import Upload from '../../../components/Upload/Upload'
import FileFolderDropzone from '../../../components/FileFolderDropzone/FileFolderDropzone'
import DataTable from '../../../components/DataTable/DataTable'
import './Home.css';
import { useDispatch, useSelector } from 'react-redux'
import { createFolder, downloadFilesAndFolder, getFilesAndFolders, removeFilesAndFolders } from '../../../store/actions/upload.actions'
import GridView from '../../../components/Grid/GridView'
import Loader from '../../../components/Loader/Loader'
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb'
import { toast } from 'react-toastify'
import DownloadNotification from '../../../components/DownloadNotification/DownloadNotification'
import { intiateDownloading } from '../../../store/reducers/upload.reducers'
import { generateId } from '../../../Utils/pipes'

const Home = () => {
    const dispatch = useDispatch();

    const [path, setPath] = useState("/")
    const [showDragDrop, setShowDragDrop] = useState(false);
    const [isGridView, setIsGridView] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [showCheckbox, setShowCheckbox] = useState(null);

    const user = useSelector((state) => state.user.user)
    const data = useSelector((state) => state.upload?.currentfilesDir)
    const loading = useSelector((state) => state.upload.loading)

    useEffect(() => {
        dispatch(getFilesAndFolders(path))
    }, [path, setPath])

    const handleItemSelect = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(itemId => itemId !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const toggleDrapDrop = () => {
        setShowDragDrop(!showDragDrop)
    }

    const handleNewFolder = (folderName) => {
        dispatch(createFolder(path, folderName))
    }


    const deleteSelectedItems = () => {
        if (selectedItems.length === 0) {
            toast.error("Please select files/Folders to Delete!")
            return;
        }
        const filteredArray = data.filter(item => selectedItems.includes(item.id));
        dispatch(removeFilesAndFolders(path, filteredArray))
    }

    const downloadSelectedItems = () => {

        if (selectedItems.length === 0) {
            toast.error("Please select files/Folders to Download!")
            return;
        }
        const filteredArray = data.filter(item => selectedItems.includes(item.id));
        const id = generateId()
        dispatch(intiateDownloading(id))   // intiate downloading 
        dispatch(downloadFilesAndFolder(path, filteredArray, id));
        setSelectedItems([])
    }

    const changeView = () => {
        setIsGridView(!isGridView)
    }

    const handlePathChange = (path) => {
        setPath(path)
    }

    return (
        <div>
            {loading && <Loader />}
            {user &&
                <DashboardHeading heading='Home' content={`Welcome back ${user.first_name}!`} />
            }

            <Breadcrumb path={path} onClick={handlePathChange} />
            <Upload
                handleNewFolder={handleNewFolder}
                toggleDrapDrop={toggleDrapDrop}
                downloadSelectedItems={downloadSelectedItems}
                deleteSelectedItems={deleteSelectedItems}
                changeView={changeView}
                isGridView={isGridView}
            />
            <div className='mt-4 mb-4'>
                {showDragDrop && <FileFolderDropzone path={path} />}
            </div>

            {isGridView ?
                <GridView
                    data={data}
                    selectedItems={selectedItems}
                    handleItemSelect={handleItemSelect}
                    showCheckbox={showCheckbox}
                    setShowCheckbox={setShowCheckbox}
                    setPath={setPath}
                    path={path}
                />
                :
                <DataTable
                    data={data}
                    selectedItems={selectedItems}
                    handleItemSelect={handleItemSelect}
                    showCheckbox={showCheckbox}
                    setShowCheckbox={setShowCheckbox}
                    setPath={setPath}
                    path={path}
                />
            }
            <DownloadNotification />

        </div>
    )
}

export default Home