import React, { useEffect, useState } from 'react'
import { Route, NavLink, Routes, useNavigate } from 'react-router-dom';
import './Dashboard.css'
import logo from "../../assets/images/logo.png"
import home from '../../assets/images/Home.png'
import hub from '../../assets/images/Hub.png'
import chat from '../../assets/images/Chat.png'
import image from '../../assets/images/Image.png'
import youtube from '../../assets/images/Youtube.png'
import trash from '../../assets/images/Trash.png'
import share from '../../assets/images/Share.png'
import hamburger from '../../assets/images/Hamburger_icon.png'
import Home from './Home/Home';
import MediaHub from './MediaHub/MediaHub';
import Photos from './MediaHub/Photos/Photos';
import Videos from './MediaHub/Videos/Videos';
import DeletedItems from './MediaHub/DeletedItems/DeletedItems';
import discount from '../../assets/images/Discount.png';
import jobs from '../../assets/images/Job.png';
import shoppingCart from '../../assets/images/Shopping cart.png';
import Header from '../../components/Header/Header'
import { useSelector } from 'react-redux';
import { Grid, Hidden, IconButton, useMediaQuery } from '@mui/material';
import UserProfile from './UserProfile/UserProfile';
import ChangePassword from './Settings/ChagePassword/ChangePassword';
// import { Help } from '@mui/icons-material';
import Help from './Settings/Help/Help';
import PrivacyPolicy from './Settings/PrivacyPolicy/PrivacyPolicy';
import PaymentHistory from './Settings/PaymentHistory/PaymentHistory';
import useAutoLogin from '../../hooks/useAutoLogin';
import CommingSoon from '../../components/CommingSoon/CommingSoon';

const Dashboard = () => {

    useAutoLogin()
    const [showSidebar, setShowSidebar] = useState(false);
    const navigate = useNavigate()
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
    const loading = useSelector((state) => state.user.loading);

    const [open, setOpen] = useState(false);
    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const isDesktopScreen = useMediaQuery('(min-width:1024px)');

    const handleDrawerOpen = () => {
        setOpen(prevOpen => !prevOpen);
    };


    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleLinkClick = () => {
        if (isMobileScreen) {
            setOpen(false); // Close drawer on link click for mobile screens
        }
    };

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const [mediaHub, setMediaHub] = useState(false);

    const toggleHub = () => {
        setMediaHub(!mediaHub)
    }

    useEffect(() => {
        // Check screen width and set showSidebar state accordingly
        const screenWidth = window.innerWidth;
        setShowSidebar(screenWidth >= 1024); // Show sidebar for screens wider than 1024px
    }, []);



    return (
        <>
            <div className="app">
                <Header isMobileScreen={isMobileScreen} handleDrawerOpen={handleDrawerOpen} isHeaderShow={true} open={open} />
                {/* Content Wrapper */}
                <div className="content-wrapper">
                    { /* Sidebar */}
                    {(isDesktopScreen || (open && isMobileScreen)) &&
                        <aside className={`sidebar hide ${isMobileScreen && 'sidebar-show'}`} style={{ background: '#FCFCFC', zIndex: 1 }}  >
                            <ul className='sidbar-menu'>
                                <li>
                                    <NavLink to='/dashboard' role='button' onClick={handleDrawerClose} >
                                        <img className='bi' src={home} alt="" />
                                        Home
                                    </NavLink>
                                </li>
                                {/* <li className={mediaHub ? 'right-arrow-rotated' : 'right-arrow'}> */}
                                <li>
                                    <NavLink >
                                        <img className='bi' src={hub} alt="" />
                                        Media Hub
                                        <CommingSoon />
                                        {/* <span style={{marginLeft:"1vmax"}} className={mediaHub ? 'right-arrow-rotated' : 'right-arrow'}></span> */}
                                    </NavLink>
                                </li>
                                {mediaHub &&
                                    <ul className='sidbar-menu'>
                                        <li>
                                            <NavLink to='/dashboard/photos'
                                                style={({ isActive }) => {
                                                    return isActive ? {
                                                        boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                    } : {};
                                                }} >
                                                <img className='bi' src={image} alt="" />
                                                Photos
                                                <CommingSoon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/dashboard/videos' style={({ isActive }) => {
                                                return isActive ? {
                                                    boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                } : {};
                                            }} >
                                                <img className='bi' src={youtube} alt="" />
                                                Videos
                                                <CommingSoon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/dashboard/shared' style={({ isActive }) => {
                                                return isActive ? {
                                                    boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                } : {};
                                            }} >
                                                <img className='bi' src={share} alt="" />
                                                Shared
                                                <CommingSoon />
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to='/dashboard/deletedItems' style={({ isActive }) => {
                                                return isActive ? {
                                                    boxShadow: '0px 2px 10px 6px rgba(0, 0, 0, 0.1)'
                                                } : {};
                                            }} >
                                                <img className='bi' src={trash} alt="" />
                                                Deleted items
                                                <CommingSoon />
                                            </NavLink>
                                        </li>

                                    </ul>

                                }
                                <li>
                                    <NavLink>
                                        <img className='bi' src={chat} alt="" />
                                        Forum
                                        <CommingSoon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink>
                                        <img className='bi' src={discount} alt="" />
                                        Pilot Jobs
                                        <CommingSoon />
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink>
                                        <img className='bi' src={jobs} alt="" />
                                        Offers
                                        <CommingSoon />

                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink>
                                        <img className='bi' src={shoppingCart} alt="" />
                                        Marketplace
                                        <CommingSoon />
                                    </NavLink>
                                </li>
                            </ul>
                        </aside>

                    }


                    {/* Main Content */}
                    <main className="main-content" style={{ padding: '2vmax', display: (isMobileScreen && open) && 'none' }} >
                        {/* <Hidden mdUp onClick={toggleSidebar} >
                            <img src={hamburger} alt="" width={'30px'} height={'30px'} />
                        </Hidden> */}
                        <Routes>
                            <Route path='' element={<Home />} />
                            <Route path='photos' element={<Photos />} />
                            <Route path='videos' element={<Videos />} />
                            <Route path='deletedItems' element={<DeletedItems />} />
                            <Route path='profile' element={<UserProfile />} />
                            <Route path='changePassword' element={<ChangePassword />} />
                            <Route path='help' element={<Help />} />
                            <Route path='privacy-policies' element={<PrivacyPolicy />} />
                            <Route path='payment-history' element={<PaymentHistory />} />
                        </Routes>
                    </main>
                </div>
            </div>
        </>
    )
}

export default Dashboard