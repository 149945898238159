import React, { useEffect } from 'react'
import Location from '../../../assets/images/Voilet-Location.png'
import Mail from '../../../assets/images/Voilet-Mail.png'
import Edit from '../../../assets/images/Edit.png'
import "./UserProfile.css"
import Avatar from '@mui/material/Avatar';
import { useMediaQuery } from '@mui/material'
import ProfileDailog from '../../../components/ProfileDailog/ProfileDailog'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader/Loader'
import MultipleSelect from '../../../components/MultipleSelect/MultipleSelect'
import Profile from '../../../assets/images/Profile.png'
import MasterPlan from '../../../assets/images/MasterPlan.png'
import Image from '../../../assets/images/i-icon.png'
import { getDroneList } from '../../../store/actions/user.actions'
import { formateDateToAbbreviatedMonthDate } from '../../../Utils/pipes'


const UserProfile = () => {
    const [open, setOpen] = React.useState(false);
    const user = useSelector((state) => state.user.user);
    const drones = useSelector((state) => state.user.DroneList);
    const loading = useSelector((state) => state.user.loading);
    const isMobileScreen = useMediaQuery('(max-width:600px)');
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getDroneList())
    }, [])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };


    return (
        <div>
            {loading && <Loader />}
            <div className='dashboard-heading d-flex justify-content-between'>
                <h1>My Profile</h1>
                <button style={{ border: 'none', background: 'transparent' }} onClick={handleClickOpen}>
                    <img src={Edit} alt="" srcset="" />
                </button>
            </div>
            <div className='row col-md-12 mt-3'>
                <div className="col-md-3 d-flex flex-column p-3 " style={{ background: '#FBFBFB', borderRadius: "20px", boxShadow: "0px 2px 6px 4px #0000000D", ...(!isMobileScreen && { minHeight: '70vh' }) }}>
                    <div className='p-3 text-center d-flex align-items-center justify-content-center'>

                        {user &&
                            <Avatar alt="Travis Howard" src={user.profile_image ?? Profile} sx={{ width: 200, height: 200 }} />
                        }
                        {/* </Badge> */}
                    </div>
                    {user && <h3 className='text-center mb-4'>{`${user.first_name} ${user.last_name}`}</h3>}



                    <div className="info d-flex ms-3">
                        <div>
                            <img src={Mail} alt="" style={{ marginRight: 10 }} />
                        </div>
                        {
                            user &&
                            <p>
                                {user.email}
                            </p>
                        }
                    </div>
                    <div className="info d-flex ms-3">
                        <div>
                            <img src={Location} alt="" style={{ marginRight: 10 }} />
                        </div>
                        {user &&
                            <p>
                                {user.address && user.address}
                            </p>
                        }
                    </div>
                    <hr />
                    <div className="info d-flex ms-3">

                        <div>
                            {user && user.planDetail.planType !== 'LifeTime' &&
                                <img src={MasterPlan} alt="" style={{ marginRight: 10 }} />
                            }
                        </div>
                        {user && (user.planDetail.planType !== 'LifeTime' ?
                            (<div style={{ fontSize: "14px" }}>
                                <strong>{user.planDetail.planName},</strong> <span style={{ color: 'grey' }} >  {user.planDetail.planSpace}  space</span>
                                <br></br>
                                <span style={{ color: 'grey' }}>Member since: </span><strong>{formateDateToAbbreviatedMonthDate(user.subscriptionDetail.currentPeriodStart)}</strong>
                            </div>)
                            :
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className='mb-3 p-3' style={{ boxShadow: "0px 4px 4px 0px #0000001A", fontSize: '14px', fontWeight: '600' }}>
                                    Not Subscribed to any of our plans yet?
                                </div>
                                <button className='subscribe-btn' style={{ fontSize: '12px !important', background: '#747DD7', color: 'white' }}>
                                    Explore Now!
                                </button>
                            </div>
                        )

                        }
                    </div>
                    {
                        user && user.planDetail.planType !== 'LifeTime' && <div className="info d-flex ms-3 mt-3 align-items-center p-2" style={{ boxShadow: '0px 4px 4px 0px #00000026', background: '#7F88E7', borderRadius: "12px" }}>
                            <div>
                                <img src={Image} alt="" style={{ marginRight: 10 }} />
                            </div>
                            <div style={{ color: "white" }}>
                                <span >
                                    Your membership will be cancelled at the end of your current billing period on
                                </span>
                                <strong > {formateDateToAbbreviatedMonthDate(user.subscriptionDetail.currentPeriodEnd)}.</strong>
                            </div>
                        </div>
                    }

                </div>
                <div className="col-md-9 ">
                        <div className="col-md-12 p-4 mb-3" style={{ background: '#FBFBFB', borderRadius: "20px", boxShadow: "0px 2px 6px 4px #0000000D" }}>
                            <div>
                                <h5>About me</h5>
                            </div>
                            <p className='p-3' style={{ background: 'white' }}>
                                {user && user.about_user}
                            </p>
                        </div>
                    <div className="col-md-12 p-4 mt-3" style={{ background: '#FBFBFB', borderRadius: "20px", boxShadow: "0px 2px 6px 4px #0000000D" }}>
                        <div>
                            <h5>Drone and Equipments</h5>
                        </div>
                        {user &&
                            <MultipleSelect drones={drones} selectedDrones={user.drone} />
                        }
                    </div>
                </div>

            </div>
            <div>
                <ProfileDailog
                    open={open}
                    onClose={handleClose}
                />
            </div>

        </div>

    )
}

export default UserProfile