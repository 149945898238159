import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./store/reducers/user.reducers";
import uploadReducer from "./store/reducers/upload.reducers";
const store = configureStore({
    reducer: {
        user: userReducer,
        upload: uploadReducer,
    }
});

export default store;