import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
import SignUp from './pages/SignUp/SignUp';
import NotFound from './pages/NotFound/NotFound';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import OtpVerify from './pages/OtpVerify/OtpVerify';
import React from 'react';
import SubscriptionPlan from './pages/SubscriptionPlan/SubscriptionPlan';
import PaymentMethod from './pages/PaymentMethod/PaymentMethod';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import Dashboard from './pages/Dashboard/Dashboard';
import Splash from './pages/Splash/Splash';
import DeleteUser from './pages/DeleteUser/DeleteUser';


function App() {

  const { isAuthenticated } = useSelector((state) => state.user)

  return (
    <Router>
      <ToastContainer />
      {/* {loading && <Loader />} */}
      <div>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
          <Route path="/" element={<Splash />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SignUp />} />
          {/* <Route path="/contact" component={Contact} /> */}
          <Route path='/forgetPassword' element={<ForgetPassword />} />
          <Route path='/otpVerify' element={<OtpVerify />} />
          {/* {isAuthenticated && <Route path='/subscripiton-plan' element={<SubscriptionPlan />} />} */}
          <Route path='/subscripiton-plan' element={<SubscriptionPlan />} />
          <Route path='/payment' element={<PaymentMethod />} />
          <Route path='/changePassword' element={<ChangePassword />} />
          <Route path='/delete' element={<DeleteUser />} />
          <Route path='/dashboard/*' element={<Dashboard />} />
          <Route path='*' element={<NotFound />} />

          <Route />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
